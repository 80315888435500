import isUrl from 'is-url-superb';

export function constructButtonChannelLink(brandUrl, mobileUrl, channel) {
  const url = new URL('https://r.bttn.io/');

  url.searchParams.set('btn_ref', channel);
  url.searchParams.set('btn_url', brandUrl);
  if (mobileUrl) {
    url.searchParams.set('btn_mobile_url', mobileUrl);
  }

  return url.toString();
}

export async function writeToClipboard(text) {
  if (window && window !== window.top) {
    // we are in an iframe, and don't have `navigator.clipboard` access

    // register response listener
    const responseMessagePromise = new Promise((resolve, reject) => {
      window.addEventListener('message', (event) => {
        if (!event.data || !event.data.type || event.data.type !== 'LINKTOOL_COPY_RESPONSE') {
          return;
        }

        if (event.data.payload && event.data.payload.success) {
          resolve();
        } else {
          reject(new Error('Linktool helper failed to write to clipboard'));
        }
      });

      setTimeout(() => reject(new Error('did not get response from Linktool helper')), 1000);
    });

    // dispatch the request
    window.parent.postMessage({ type: 'LINKTOOL_COPY_REQUEST', payload: text }, '*');

    return responseMessagePromise;
  }

  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text);
  }

  // This can happen if you're in an unsecure context that isn't localhost
  // e.g. loading from GitHub Pages over HTTP
  throw new Error('Unable to write to clipboard');
}

// Returns either an error message string or `null`.
export function validateUrl(val) {
  const GENERIC_VALIDATION_MESSAGE = 'does not appear to be a valid URL';

  if (typeof val !== 'string') {
    return GENERIC_VALIDATION_MESSAGE;
  }

  if (!val.startsWith('http://') && !val.startsWith('https://')) {
    return 'must start with either http:// or https://';
  }

  if (val.match(/^https?:\/\/[\w-]+\.bttn\.io/)) {
    return 'can\'t be a Button link';
  }

  return isUrl(val) ? null : GENERIC_VALIDATION_MESSAGE;
}

export function mapObject(obj, iteratee) {
  if (typeof obj !== 'object' || obj === null) {
    return null;
  }

  return Object.keys(obj).map(k => {
    return iteratee(obj[k], k, obj); // called with: value, key, obj
  });
}
