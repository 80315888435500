import React from 'react';
import cx from 'classnames';
import { validateUrl } from '../util';
import './URLInput.css';

export default class URLInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      errorMessage: null,
    };

    this._onChange = this._onChange.bind(this);
  }

  _onChange(e) {
    const { value } = e.target;
    const err = validateUrl(value);

    this.setState({
      value,
      errorMessage: err,
    });

    this.props.onChange(value, !err);
  }

  render() {
    const isValid = this.state.errorMessage === null;
    return <div className="URLInput">
      <input aria-label={this.props.label} type="text" className={cx('URLInput__input', { 'URLInput__input--invalid': !isValid })} onChange={this._onChange} />
      {
        this.state.errorMessage && <div className="URLInput__message">
          {this.state.errorMessage}
        </div>
      }
    </div>;
  }
}
