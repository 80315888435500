import React from 'react';
import cx from 'classnames';
import { writeToClipboard } from '../util';
import './Result.css';

export default class Result extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resultCopied: false,
    };
  }

  handleResultFocus = async (e) => {
    e.preventDefault();
    await writeToClipboard(this.props.generatedUrl);
    this.setState({ resultCopied: true });
  }

  handleResultBlur = (e) => {
    e.preventDefault();
    this.setState({ resultCopied: false });
  }

  onResetClick = () => {
    if (this.props.onResetClick) {
      this.props.onResetClick();
    }
  }

  render() {
    return <>
      <div className="Result">
        <div className="Result__title">Your Button Link</div>
        <code aria-label="Generated Button URL" className={cx('Result__value', { 'Result__value--copied': this.state.resultCopied })} onBlur={this.handleResultBlur} onClick={this.handleResultFocus}>{this.props.generatedUrl}</code>
        <label className="Result__label">
          { this.state.resultCopied ? (
            <b>Copied to clipboard!</b>
          ) : 'Click the text above to copy.'}
        </label>
      </div>
      <button onClick={this.onResetClick}>Start over</button>
    </>;
  }
}
