import React from 'react';
import SelectInput from './SelectInput';
import URLInput from './URLInput';
import './InputForm.css';

const CHANNEL_OPTIONS = {
  'org-4b1cc2e8b39399ec': 'Paid Social',
  'org-3367c4a5ff91a460': 'Email',
  'org-4bea005cb9eb98b1': 'SMS Marketing',
};

const queryParams = new URLSearchParams(window.location.search);

export default class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      mobileUrl: '',
      isUrlValid: false,
      isMobileUrlValid: true,
      channel: queryParams.get('initial_channel_organization_id') || 'org-4b1cc2e8b39399ec',
    };
  }

  updateInputUrl = (val, isValid) => {
    this.setState({
      url: val,
      isUrlValid: isValid,
    });
  }

  updateMobileUrl = (val, isValid) => {
    this.setState({
      mobileUrl: val,
      isMobileUrlValid: isValid,
    });
  }

  updateChannel = (val) => {
    this.setState({
      channel: val,
    });
  }

  triggerGenerateUrl = () => {
    this.props.generateUrl(this.state.url, this.state.mobileUrl, this.state.channel);
  }

  render() {
    return <div className="InputForm">
      <div className="InputFormItem">
        <div className="InputFormItem__title">Brand URL</div>
        <label className="InputFormItem__label">The URL of the brand site where you wish users ultimately route. This should be the Brand's URL, and not any intermediary tracking links (see below). This link can contain query parameters, i.e. https://brand.com/path?query=params</label>
        <URLInput label="input URL" onChange={this.updateInputUrl} />
      </div>
      <div className="InputFormItem">
        <div className="InputFormItem__title">Custom Fallback URL (optional)</div>
        <label className="InputFormItem__label">If the URL placed in your Ad today is not the Brand URL (i.e. a DoubleClick link), put that link here, i.e. https://mobile.tracker/url. Button will route users through this URL if we cannot route to app.</label>
        <URLInput label="mobile URL" onChange={this.updateMobileUrl} />
      </div>
      <div className="InputFormItem">
        <div className="InputFormItem__title">Channel</div>
        <label className="InputFormItem__label">The platform that will be sending traffic to your Button Link.</label>
        <SelectInput options={CHANNEL_OPTIONS} onChange={this.updateChannel} />
      </div>
      <button className="InputForm__submit" aria-label="Generate Button Link" onClick={this.triggerGenerateUrl} disabled={!this.state.isUrlValid || (!this.state.isMobileUrlValid && this.state.mobileUrl)}>Create Button Link</button>
    </div>;
  }
}
