import React from 'react';
import * as Sentry from '@sentry/react';
import { constructButtonChannelLink } from './util';
import './App.css';
import Result from './components/Result';
import InputForm from './components/InputForm';

const errorFallbackContent = <div className="Error">
  <h2>Something went wrong.</h2>
  <p>
    Unfortunately, the Button Link tool isn't working properly right now.
    The Button team has been notified about this issue.
  </p>
</div>;

const queryParams = new URLSearchParams(window.location.search);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generatedUrl: null,
    };

    this.resetForm = this.resetForm.bind(this);
  }

  generateUrl = (url, mobileUrl, channel) => {
    const result = constructButtonChannelLink(url, mobileUrl, channel);

    this.setState({
      generatedUrl: result,
    });
  }

  resetForm() {
    this.setState({
      url: '',
      mobileUrl: '',
      isUrlValid: false,
      isMobileUrlValid: true,
      channel: queryParams.get('initial_channel_organization_id') || 'org-4b1cc2e8b39399ec',
      generatedUrl: null,
    });
  }


  render() {
    const shouldShowForm = this.state.generatedUrl === null;
    return (
      <Sentry.ErrorBoundary fallback={errorFallbackContent}>
        <div className="App">
          { shouldShowForm ? (
            <InputForm generateUrl={this.generateUrl} />
          ) : (
            <Result onResetClick={this.resetForm} generatedUrl={this.state.generatedUrl} />
          )}
        </div>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
