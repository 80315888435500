import React from 'react';
import cx from 'classnames';
import { mapObject } from '../util';
import './SelectInput.css';

export default class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    const initialChannelValues = Object.keys(props.options || {});
    const queryParams = new URLSearchParams(window.location.search);

    this.state = {
      value: queryParams.get('initial_channel_organization_id') || initialChannelValues[0] || '',
      errorMessage: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(val) {
    this.setState({
      value: val,
    });
    this.props.onChange(val);
  }

  render() {
    const { options } = this.props;

    return <div className="SelectInput">
      {
        mapObject(options, (friendlyName, channelValue) => <div key={channelValue} className={cx('SelectInput__option', { 'SelectInput__option--selected': this.state.value === channelValue })}>
          <input
            type="radio"
            onChange={() => this.onChange(channelValue)}
            checked={this.state.value === channelValue}
            value={channelValue}
          ></input>
          <label>{friendlyName}</label>
        </div>)
      }
    </div>;
  }
}
